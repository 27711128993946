const Venmo = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="29"
      viewBox="0 0 44 29"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5266 1.94775C41.8003 1.94775 42.8424 2.98986 42.8424 4.26354V25.1056C42.8424 26.3793 41.8003 27.4214 40.5266 27.4214H3.47399C2.20031 27.4214 1.1582 26.3793 1.1582 25.1056V4.26354C1.1582 2.98986 2.20031 1.94775 3.47399 1.94775H40.5266Z"
        fill="#3D95CE"
      />
      <path
        d="M28.5709 10.47C28.5709 14.0178 25.6102 18.6274 23.2064 21.8637H17.7179L15.5156 8.39737L20.322 7.9319L21.4857 17.5135C22.5742 15.7002 23.9162 12.853 23.9162 10.9112C23.9162 9.84821 23.7378 9.12453 23.4599 8.52821L27.8368 7.62158C28.3428 8.47611 28.5709 9.35727 28.5709 10.47Z"
        fill="white"
      />
    </svg>
  )
}

export default Venmo
